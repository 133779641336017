@font-face {
  font-family: 'LEDCounter7';
  src: url('#{$font-path}/LEDCounter7.eot');
  src: url('#{$font-path}/LEDCounter7?#iefix') format('embedded-opentype'),
       url('#{$font-path}/LEDCounter7.woff') format('woff'),
       url('#{$font-path}/LEDCounter7.ttf') format('truetype'),
       url('#{$font-path}/LEDCounter7.svg#LEDCounter7') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon';
  src: url('#{$font-path}/simplon-mono-regular.eot');
  src: url('#{$font-path}/simplon-mono-regular?#iefix') format('embedded-opentype'),
       url('#{$font-path}/simplon-mono-regular.woff') format('woff'),
       url('#{$font-path}/simplon-mono-regular.ttf') format('truetype'),
       url('#{$font-path}/simplon-mono-regular.svg#SimplonBPMono-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Simplon';
  src: url('#{$font-path}/simplon-mono-bold.eot');
  src: url('#{$font-path}/simplon-mono-bold?#iefix') format('embedded-opentype'),
       url('#{$font-path}/simplon-mono-bold.woff') format('woff'),
       url('#{$font-path}/simplon-mono-bold.ttf') format('truetype'),
       url('#{$font-path}/simplon-mono-bold.svg#SimplonBPMono-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
