@import "variables";
@import "normalize";
@import "reboot";
@import "fonts";
@import "vendor";
@import "components";

img {
  max-width: 100%;
}

button {
  color: white;
  background: #121212;
  padding: 9px 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border:none;
}

select {
  height: 30px;
  padding: 0 24px 0 10px;
  -webkit-appearance: none;
  border: 1px solid black;
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: right 7px center;
  background-color: white;
  background-color: transparentize(#FFF, 0.2);
  text-transform: uppercase;

  @media (max-width: $screen-bp-horizontal - 1px) {
    font-size: 16px;  
  }
}

.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background: {
    image: url(/images/background.jpg);
    position: center;
    size: cover;
  }
  opacity: 0.75;
}

.site-content {
  padding: $ticker-height 0;
  position: relative;
}

.page-content {
  @media (min-width: $screen-bp-horizontal) {
    min-height: calc(100vh - #{$ticker-height * 2} );
    display: flex;
    align-items: center;    
  }
}

.site-info {
  display: block;
  text-align: center;
  margin: 30px auto;
  padding: 0 $site-padding-horizontal;
  max-width: 400px;

  @media (min-width: $screen-bp-horizontal) {
    position: absolute;
    z-index: 1;
    top: $ticker-height;
    display: inline-block;
    margin-top: $site-padding-horizontal;
    margin-left: $site-padding-horizontal * 0.5;
    text-align: left;
  }

  h1 {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .quote {
    margin-bottom: 10px;
    line-height: 1.9;
    font-size: 11px;
    font-style: italic;
  }

  .quote__line {
    background-color: white;
    color: black;
    padding: 3px 10px 3px 5px;
  }
}

.socials {
  margin-top: 15px;
}

.socials__link {
  display: inline-block;
  padding: 0 5px;
  // font-weight: bold;

  @media (min-width: $screen-bp-horizontal) {
    display: block;
    padding: 0;

    & + & {
      margin-top: 8px;
    }    
  }
}

.slider-container {
  width: 100%;
}

.slider {
  height: 100%;
  transition: opacity 300ms ease-out;

  &.is-loading {
    opacity: 0;
    transition: opacity 0ms linear;
  }
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
  height: 300px;
  width: 75%;

  @media (min-width: $screen-bp-horizontal) {
    height: 450px;
    // max-height: 450px;
    height: 50vh;
    width: 60%;
  }

  @media (min-width: 1280px) {
    height: 60vh;
  }

  &:not(.is-selected) {
    transform: scale(0.9);
    opacity: 0.6;
  }

  // Use before pseudo element to create a blocker to prevent interacting with slide contents
  // unless selected
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &.is-selected:before {
    display: none;
  }

  img {
    max-height: 100%;
    max-width: 90%;
    margin: 0px auto;
    display: block;
    transform: translateZ(0); // Fix blurriness
  }
}

.slide-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  margin: 0 auto;

  @media (min-width: $screen-bp-horizontal) {
    width: 80%;
  }
}

.captions {
  margin: 30px auto;
  padding: 0 $site-padding-horizontal;
  max-width: 400px;
  text-align: center;

  @media (min-width: $screen-bp-horizontal) {
    position: absolute;
    bottom: $ticker-height;
    margin-left: $site-padding-horizontal * 0.5;
    text-align: left;
    max-width: 550px;
  }
}

.caption {
  display: none;

  &.is-active {
    display: block;
  }
}

.caption__title {
  text-transform: uppercase;
  margin-bottom: 11px;
  // color: white;
}

.caption__text {
  font-size: 90%;
  // color: white;

  p {
    margin-bottom: 10px;
  }
}

#parental-advisory {
  position: absolute;
  bottom: 30px + $ticker-height;
  right: 40px;
  width: 70px;
  transform: translateZ(0);

  display: none;

  @media (min-width: $screen-bp-horizontal) {
    display: block;
  }

}

// Responsive embed
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /*16:9*/
  height: 0; 
  overflow: hidden;
}
 
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container__play-btn,
.video-container__player,
.video-container__still-img {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
}

.video-container__play-btn {
  height: 100%;
  opacity: .2;
  transition: all 500ms ease-out;
  z-index: 41;

  &:before {
    content: "WATCH";
    letter-spacing: 2px;
    font-size: 17px;
    transition: opacity 500ms ease-out;
  }

  .no-touch &:before {
    opacity: 0;
  }

  .touch &,
  .video-container:hover &,
  .video-container:focus & {
    opacity: 0.6;
    transform: scale(1.03) translateZ(0);

    &:before {
      opacity: 1;
    }
  }

  .video-container--playing &,
  .video-container--loading & {
    opacity: 0;
    pointer-events: none;
  }
}

.video-container__still {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 500ms ease-in-out;

  .video-container--playing &,
  .video-container--loading & {
    opacity: 0;
    pointer-events: none;
  }
  img {
    max-width: 100%;
  }
}

.video-container__player {
    height: 100%
}